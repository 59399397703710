<template>
  <v-container>
    <app-loading v-if="loadingData"/>
    <new-tax v-if="$store.state.tax.showNewModal" />
    <v-card v-else>
      <v-card-title v-if="article">{{
          $vuetify.lang.t('$vuetify.titles.edit', [ !article.service ? $vuetify.lang.t('$vuetify.menu.product') : $vuetify.lang.t('$vuetify.menu.service')])
        }}</v-card-title>
      <v-card-text v-if="article">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $vuetify.lang.t('$vuetify.panel.basic') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                ref="form"
                v-model="formValid"
                style="padding: 0"
                lazy-validation
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="article.name"
                      :label="$vuetify.lang.t('$vuetify.firstName')"
                      :rules="formRule.required"
                      required
                      autofocus
                    />
                  </v-col>
                  <v-col>
                    <v-autocomplete
                      v-model="article.um"
                      :disabled="isActionInProgress"
                      :items="arrayUM"
                      :label="$vuetify.lang.t('$vuetify.um.name')"
                      :filter="customFilter"
                      item-text="name"
                      auto-select-first
                      return-object
                    >
                      <template v-slot:selection="data">
                        {{ $vuetify.lang.t('$vuetify.um.' + data.item.name) }}
                        <template v-if="data.item.presentation">
                          <template v-if="data.item.presentation.split('__').length === 0">
                            ({{ data.item.presentation }})
                          </template>
                          ({{ data.item.presentation.split('__')[0] }}<sup>{{ data.item.presentation.split('__')[1] }}</sup>)
                        </template>
                      </template>
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content>
                            {{ $vuetify.lang.t('$vuetify.um.' + data.item.header) }}
                          </v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ $vuetify.lang.t('$vuetify.um.' + data.item.name) }}
                              <template v-if="data.item.presentation">
                                <template v-if="data.item.presentation.split('__').length === 0">
                                  ({{ data.item.presentation }})
                                </template>
                                ({{ data.item.presentation.split('__')[0] }}<sup>{{ data.item.presentation.split('__')[1] }}</sup>)
                              </template>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field-simplemask
                      v-model="article.barCode"
                      :label="$vuetify.lang.t('$vuetify.barCode')"
                      :properties="{
                              clearable: true,
                            }"
                      :options="{
                              inputMask: '##-####-####-###',
                              outputMask: '#############',
                              empty: null,
                              alphanumeric: true,
                            }"
                      :focus="focus"
                      @focus="focus = false"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      style="margin-top: 0px"
                      v-model="article.ref"
                      :label="$vuetify.lang.t('$vuetify.articles.ref')"
                      :rules="formRule.required"
                      required
                      @keypress="numbers"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field-money
                      v-model="article.cost"
                      :disabled="article.composite"
                      :label="$vuetify.lang.t('$vuetify.articles.cost')"
                      required
                      :properties="{
                              suffix: getCurrency
                            }"
                      :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.00,
                            }"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-y
                      transition="fab-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          style="margin-top: 15px"
                          :label="$vuetify.lang.t('$vuetify.menu.category')"
                          v-bind="attrs"
                          v-on="on"
                          :value="getCategoryName"
                          readonly
                          :rules="formRule.required"
                          required
                        >
                        </v-text-field>
                      </template>
                      <v-card height="250">
                        <v-sheet>
                          <v-text-field
                            v-model="search"
                            :label="$vuetify.lang.t('$vuetify.actions.search')"
                            hide-details
                            clearable
                            clear-icon="mdi-close-circle-outline"
                          />
                          <v-spacer/>
                          <v-btn
                            class="mb-2"
                            color="primary"
                            @click="addCategory(null)"
                          >
                            <v-icon>mdi-plus</v-icon>
                            {{ $vuetify.lang.t('$vuetify.actions.newF') }}
                          </v-btn>
                        </v-sheet>
                        <v-treeview
                          style="background-color: white"
                          activatable
                          :search="search"
                          :filter="filter"
                          :items="getCategoriesLanguage"
                          item-text="category"
                        >
                          <template v-slot:label="{ item }" >
                            <v-list-item two-line @click="selectCategory(item)" @dblclick="selectDBCategory(item);">
                              <v-list-item-icon v-if="item.id !== ''">
                                <v-spacer />
                                <v-divider />
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      style="margin-top: 10px"
                                      class="mr-2"
                                      small
                                      color="primary"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-tag
                                    </v-icon>
                                  </template>
                                  <span>{{ $vuetify.lang.t('$vuetify.component.select_one') }}</span>
                                </v-tooltip>
                                <v-tooltip top style="margin-top: 15px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      class="mr-2"
                                      small
                                      color="withe"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="addCategory(item)"
                                    >
                                      mdi-plus
                                    </v-icon>
                                  </template>
                                  <span>{{ $vuetify.lang.t('$vuetify.actions.add', [$vuetify.lang.t('$vuetify.menu.category')]) }}</span>
                                </v-tooltip>
                              </v-list-item-icon>
                              <v-list-item-title>
                        <span v-if="item.id" class="subtitle-2 font-weight-light">
                          {{UpperCaseFirstLetter(item.category) }}
                        </span>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-treeview>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            @click="menu = false"
                          >
                            <v-icon>mdi-close-circle</v-icon>
                            {{$vuetify.lang.t('$vuetify.actions.cancel')}}
                          </v-btn>
                          <v-btn
                            color="primary"
                            text
                            @click="acceptCategory"
                          >
                            {{$vuetify.lang.t('$vuetify.actions.save')}}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="article.taxes"
                      chips
                      clearable
                      deletable-chips
                      :items="taxes"
                      multiple
                      :label="$vuetify.lang.t('$vuetify.tax.name')"
                      item-text="name"
                      :loading="isTaxLoading"
                      :disabled="!!isTaxLoading"
                      return-object
                      required
                      :rules="formRule.country"
                    >
                      <template v-slot:append-outer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              @click="$store.dispatch('tax/toogleNewModal',true)"
                            >
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>{{ $vuetify.lang.t('$vuetify.titles.newAction') }}</span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4">
                    <v-menu
                      ref="menuDate"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      :return-value.sync="article.expire_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="article.expire_date"
                          :label="$vuetify.lang.t('$vuetify.articles.due_date')"
                          readonly
                          clearable
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="article.expire_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuDate = false"
                        >
                          <v-icon>mdi-close-circle</v-icon>
                          {{$vuetify.lang.t('$vuetify.actions.cancel')}}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuDate.save(article.expire_date)"
                        >
                          {{$vuetify.lang.t('$vuetify.actions.save')}}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="article.description"
                      :counter="250"
                      maxlength="250"
                      :label="$vuetify.lang.t('$vuetify.access.description')"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $vuetify.lang.t('$vuetify.articles.inventory') }}<v-spacer/>
              <small> {{ $vuetify.lang.t('$vuetify.menu.shop') }}</small>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <article-shop :article="article"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $vuetify.lang.t('$vuetify.representation.representation') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <representation :article="article"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="$router.push({name:'product_list'})"
        >
          <v-icon>mdi-close</v-icon>
          {{ smallScreen ? '': $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn
          class="mb-2"
          color="primary"
          :disabled="!valid || isActionInProgress"
          :loading="isActionInProgress"
          @click="updateArticleHandler"
        >
          {{ smallScreen ? '': $vuetify.lang.t('$vuetify.actions.save') }}
          <v-icon>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import categories from '../../../../data/categories'
import ArticleShop from '../manager-article/ArticleShop'
import AppLoading from '../../../../components/core/AppLoading'
import Representation from '../manager-article/Representation'
import NewTax from '../../../tax/commerce/NewTax'

export default {
  name: 'EditArticle',
  components: { NewTax, Representation, AppLoading, ArticleShop },
  data () {
    return {
      loadingCat: false,
      article: null,
      loadingData: false,
      getCategoryName: null,
      localCategory: null,
      search: '',
      menu: false,
      menuDate: false,
      formValid: false,
      focus: false,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('article', ['isActionInProgress', 'editArticle']),
    ...mapState('tax', ['taxes', 'isTaxLoading']),
    ...mapState('statics', ['arrayUM']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapGetters('category', ['allCategories', 'allActivated', 'getCategoryAdded']),
    valid () {
      return this.article ? this.article.name !== '' : false
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ' '
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCategoriesLanguage () {
      const localCat = categories[this.$vuetify.lang.current]()
      let newLocal = []
      if (this.allActivated.length > 0) {
        newLocal = this.updateActiveCat(localCat, newLocal)
        this.allActivated.forEach((v) => {
          const cat = this.allCategories.filter(cat => parseInt(cat.id) === parseInt(v))[0]
          if (cat.company_id) {
            if (cat.parent_id) {
              this._find(newLocal, 'id', cat.parent_id, cat)
            } else {
              newLocal.push(cat)
            }
          }
        })
      }
      return newLocal
    },
    progress () {
      return Math.min(100, this.value.length * 10)
    },
    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    }
  },
  async created () {
    this.loadingData = true
    await this.getEditArticle({ id: atob(this.$route.params.hash) }).then(() => {
      this.article = this.editArticle
    })
    this.loadingData = false
    if (this.article.expire_date) { this.article.expire_date = this.article.expire_date.split(' ')[0] }
    this.loadingCat = true
    await this.getCategories().then((callbackfn, thisArg) => {
      this.getCategoriesLanguage.forEach((v) => {
        const cat = this.searchTree(v, this.editArticle.category_id)
        if (cat) {
          this.localCategory = cat
          this.acceptCategory(cat)
        }
      })
    })
    this.loadingCat = false
    await this.getTaxes()
    await this.getMarks()
  },
  methods: {
    ...mapActions('category', ['getCategories']),
    ...mapActions('tax', ['getTaxes']),
    ...mapActions('mark', ['getMarks']),
    ...mapActions('article', ['createArticle', 'fetchArticleNumber', 'updateArticle', 'toogleNewModal', 'getEditArticle']),
    addCategory (category) {
      this.parentCategory = category
      this.$store.state.category.showNewModal = true
    },
    updateActiveCat (collection, newLocal) {
      for (const o of collection) {
        const ele = {
          category: o.category,
          id: o.id,
          children: []
        }
        if (o.children && Array.isArray(o.children) && o.children.length > 0) {
          const _o = this.updateActiveCat(o.children, ele.children)
          if (!Array.isArray(_o)) {
            ele.children.push(_o)
          }
        }
        if (this.allActivated.indexOf(ele.id) > -1 || ele.children.length > 0) {
          if (!Array.isArray(ele)) {
            newLocal.push(ele)
          }
        }
      }
      return newLocal
    },
    _find (collection, key, value, child) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            if (!o.children && child) { o.children = [] }
            if (child) { o.children.push(child) }
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value, child)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    searchTree (element, elementId) {
      if (parseInt(element.id) === parseInt(elementId)) {
        return element
      } else if (element.children != null) {
        let i = 0
        let result = null
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], elementId)
        }
        return result
      }
      return null
    },
    selectDBCategory (item) {
      this.selectCategory(item)
      this.acceptCategory()
    },
    selectCategory (category) {
      this.localCategory = category
    },
    acceptCategory () {
      this.article.category = this.localCategory
      this.article.category_id = this.localCategory.category_id
      this.getCategoryName = this.article.category ? this.UpperCaseFirstLetter(this.article.category.category) : null
      this.menu = false
    },
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    numbers (event) {
      const regex = new RegExp('^\\d+(.\\d{1,2})?$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    customFilter (item, queryText, itemText) {
      return this.$vuetify.lang.t('$vuetify.um.' + item.name).toLowerCase().indexOf(queryText.toLowerCase()) > -1
    },
    updateArticleHandler () {
      this.loadingData = true
      this.updateArticle(this.article).then(() => {
        this.$router.push({ name: 'product_list' })
      }).catch(() => {
        this.loadingData = false
      })
    }
  }
}
</script>

<style scoped>
  .scroll {
    overflow-y: scroll
  }
</style>
